import 'twin.macro'

import RingLoader from 'components/RingLoader'

// TODO: Add a body scroll lock when component mounts and release it when component unmounts
const FullPageLoader = () => {
  return (
    <div tw='fixed inset-0 z-50 bg-black/25'>
      <RingLoader tw='w-20 h-20 [--ring-stroke-width: 4px] fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2' />
    </div>
  )
}

export default FullPageLoader
