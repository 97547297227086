import { pathnames } from 'router/pathnames'
import { TaskTrackerTypes } from 'share/types/task-tracker-types'

const routeMapper: Record<TaskTrackerTypes, string> = {
  MESSAGE_SUPER_APP_APPLICATION_PRE_APPROVE: pathnames.lead,
  BORROWER_INITIATED_LOAN: pathnames.lead,

  BORROWER_CONDITIONS_SELECTED: pathnames['lead-otp-confirmation'],
  BORROWER_ENTERED_PHONE_VERIFICATION_CODE: pathnames['lead-otp-confirmation'],
  BORROWER_REQUESTED_NEW_PHONE_VERIFICATION_CODE: pathnames['lead-otp-confirmation'],
  BORROWER_OTP_VERIFICATION_ATTEMPTS_EXCEEDED: pathnames['lead-otp-confirmation'],
  BORROWER_OTP_CODE_INCORRECT: pathnames['lead-otp-confirmation'],

  BORROWER_OTP_CODE_SUCCESSFUL: pathnames.scoring,
  INTERNAL_CHECKS_SUCCESSFUL: pathnames.scoring,

  ALL_CHECKS_SUCCESSFUL: pathnames.insurance,
  BORROWER_CHOSE_FREEDOM: pathnames.insurance,
  BORROWER_CHOSE_ANOTHER_COMPANY: pathnames.insurance,

  NEED_TAKE_CAR_PHOTO: pathnames.photocontrol,
  
  PLEDGE_APPROVED: pathnames['waiting-collateral'],
  BORROWER_PROVIDED_CAR_PHOTO: pathnames['waiting-collateral'],
  COLLATERAL_DECISION_RECEIVED: pathnames['waiting-collateral'],

  BORROWER_CAN_CONFIRM_CHOOSEN_CONDITIONS: pathnames['pledge-confirmation'],
  BORROWER_CANNOT_CONFIRM_CHOOSEN_CONDITIONS: pathnames['pledge-confirmation'],
  NECESSARY_CONFIRM_CHOOSEN_CONDITIONS: pathnames['pledge-confirmation'],
  BORROWER_AGREED_CONDITIONS_LOAN: pathnames['pledge-confirmation'],

  BORROWER_NEEDS_UPLOAD_REAL_ESTATE_DOCUMENTS: pathnames['documents-attachment'],
  ESTIMATION_ALL_CLIENT_DOCUMENTS: pathnames['waiting-estimator'],
  ESTIMATION_RESULT_RECEIVED: pathnames['waiting-estimator'],

  BORROWER_PROVIDED_SPOUSE_PHONE: pathnames['spouse-confirmation'],
  NECESSARY_PROVIDE_SPOUSE_DETAILS: pathnames['spouse-confirmation'],
  SPOUSE_NOT_OWN_THE_NUMBER_PROVIDED: pathnames['spouse-confirmation'],

  SPOUSE_OWN_THE_NUMBER_PROVIDED: pathnames['spouse-sms-sended'],
  SPOUSE_INITIATED_AGREE_DEPOSIT: pathnames['spouse-sms-sended'],
  SPOUSE_REQUESTED_NEW_PHONE_VERIFICATION_CODE: pathnames['spouse-sms-sended'],
  SPOUSE_OTP_VERIFICATION_ATTEMPTS_EXCEEDED: pathnames['spouse-sms-sended'],
  SPOUSE_ENTERED_PHONE_VERIFICATION_CODE: pathnames['spouse-sms-sended'],
  SPOUSE_OTP_CODE_INCORRECT: pathnames['spouse-sms-sended'],
  SPOUSE_OTP_CODE_SUCCESSFUL: pathnames['spouse-sms-sended'],
  SPOUSE_NEED_INITIATE_DOCUMENTS_SIGNING: pathnames['spouse-sms-sended'],
  SPOUSE_INITIATED_BIOMETRY: pathnames['spouse-sms-sended'],
  REDIRECT_SPOUSE_TO_VIDEO_IDENTIFICATION: pathnames['spouse-sms-sended'],
  SPOUSE_VIDEO_IDENTIFICATION_FAILURE: pathnames['spouse-sms-sended'],
  SPOUSE_VIDEO_IDENTIFICATION_SUCCESSFUL: pathnames['spouse-sms-sended'],
  SPOUSE_RESULT_BIOMETRY_RECEIVED: pathnames['spouse-sms-sended'],
  SPOUSE_SUCCESSFULLY_SIGNED_CONSENT_FOR_PLEDGE: pathnames['spouse-sms-sended'],
  SPOUSE_AGREED_LOAN: pathnames['spouse-sms-sended'],

  BORROWER_IS_NOT_MARRIED: pathnames['egov-update-status'],
  BORROWER_REPORTED_THAT_UPDATED_MARITAL_STATUS: pathnames['egov-update-status'],

  NECESSARY_PROVIDE_ACCESS_DIGITAL_DOCUMENTS: pathnames['digital-docs'],
  BORROWER_DIGITAL_DOCUMENTS_CODE_INCORRECT: pathnames['digital-docs'],
  BORROWER_ATTEMPTS_DIGITAL_DOCUMENTS_CODE_ARE_OVER: pathnames['digital-docs'],
  BORROWER_ENTERED_DIGITAL_DOCUMENTS_CODE: pathnames['digital-docs'],
  BORROWER_REQUESTED_NEW_PROVIDING_DIGITAL_DOCUMENTS_CODE: pathnames['digital-docs'],

  REGISTRATION_IN_BANKING_SYSTEM_STARTED: pathnames['documents-generation'],
  REGISTRATION_IN_BANK_SYSTEM_COMPLETED: pathnames['documents-generation'],
  DOCUMENTS_GENERATED: pathnames['documents-generation'],

  NEED_WAIT_WORK_TIME: pathnames['waiting-page'],
  DOCUMENT_VALIDATION_SUCCESS: pathnames['documents-sign'],

  BORROWER_NEED_INITIATE_DOCUMENTS_SIGNING_FORENSICS: pathnames['certification-center'],
  BORROWER_VIDEO_IDENTIFICATION_FAILURE_FORENSICS: pathnames['certification-center'],
  DATA_TRANSFERRED_TO_FORENCIS: pathnames['certification-center'],
  BORROWER_VIDEO_IDENTIFICATION_SUCCESSFUL_FORENSICS: pathnames['certification-center-otp'],
  BORROWER_SIGNATURE_CONFIRMATION_OTP_CODE_INCORRECT: pathnames['certification-center-otp'],
  BORROWER_SIGNATURE_CONFIRMATION_OTP_VERIFICATION_ATTEMPTS_EXCEEDED: pathnames['certification-center-otp'],

  BORROWER_NEED_INITIATE_DOCUMENTS_SIGNING_DID_AITU: pathnames['video-identification'],
  BORROWER_INITIATED_DOCUMENTS_SIGNING: pathnames['video-identification'],
  BORROWER_INITIATED_VIDEO_IDENTIFICATION: pathnames['video-identification'],
  BORROWER_RESULT_BIOMETRY_RECEIVED: pathnames['video-identification'],
  REDIRECT_BORROWER_TO_VIDEO_IDENTIFICATION: pathnames['video-identification'],
  BORROWER_VIDEO_IDENTIFICATION_FAILURE: pathnames['video-identification'],

  BORROWER_SIGNATURE_CONFIRMATION_OTP_CODE_SUCCESSFUL: pathnames['pledge-final-waiting'],
  BORROWER_VIDEO_IDENTIFICATION_SUCCESSFUL: pathnames['car-final-waiting'],
  LOAN_SUCCESSFULLY_PROCESSED: pathnames.success,

  // Rejections
  LOAN_REFUSAL: pathnames.rejected,
}

export const getRouteByTaskTrackerStatus = (status: TaskTrackerTypes) => {
  return routeMapper[status]
}
