import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { rootReducer } from 'redux/reducers'
import TokenService from 'services/token.service'

export type AppConnectionState = 'init' | 'success' | 'error'

export interface LazyAppStateSliceState {
  isAuthTokensPresent: boolean
  authState: AppConnectionState
  webSocketState: AppConnectionState
  progress: number
}

const accessToken = TokenService.getAccessToken()

const initialState: LazyAppStateSliceState = {
  isAuthTokensPresent: accessToken ? true : false,
  authState: 'init',
  webSocketState: 'init',
  progress: 0
}

const appStateSlice = createSlice({
  name: 'appStateSlice',
  initialState,
  reducers: {
    setIsAuthTokensPresent(state, { payload }: PayloadAction<boolean>) {
      state.isAuthTokensPresent = payload
    },
    setAuthState(state, { payload }: PayloadAction<AppConnectionState>) {
      state.authState = payload
    },
    setWebSocketState(state, { payload }: PayloadAction<AppConnectionState>) {
      state.webSocketState = payload
    },
    setProgress(state, { payload }: PayloadAction<number>) {
      state.progress = payload
    }
  }
})

const injectedReducer = rootReducer.inject(appStateSlice)

const selectAppStateSlice = injectedReducer.selector(state => state.appStateSlice)
const selectAppProgress = createSelector([selectAppStateSlice], state => state.progress)
// App state selectors
const selectIsAuthTokensPresent = createSelector([selectAppStateSlice], state => state.isAuthTokensPresent)
const selectIsAuthError = createSelector([selectAppStateSlice], state => state.authState === 'error')
const selectIsWebSocketConnected = createSelector([selectAppStateSlice], state => state.webSocketState === 'success')
const selectIsWebSocketError = createSelector([selectAppStateSlice], state => state.webSocketState === 'error')

export const selectAppState = createSelector(
  [selectIsAuthTokensPresent, selectIsAuthError, selectIsWebSocketConnected, selectIsWebSocketError],
  (isAuthenticated, isAuthError, isWebSocketConnected, isWebSocketError) => ({
    isAppReady: isAuthenticated && isWebSocketConnected && !isWebSocketError,
    isAppError: isWebSocketError || isAuthError
  })
)

// Export actions
export const { setIsAuthTokensPresent, setAuthState, setWebSocketState, setProgress } = appStateSlice.actions

// Export selectors
export { selectAppProgress, selectIsAuthTokensPresent }

export default appStateSlice
