import 'i18n/config'
import 'assets/styles/index.css'
import { initializeSentry, addInitialBreadcrumbToSentry } from './sentry-integration.ts'

import React from 'react'
import ReactDOM from 'react-dom/client'
import AppProviders from './AppProviders.tsx'
import AppRouter from './App.tsx'
import GlobalStyles from 'assets/styles/GlobalStyles.tsx'

initializeSentry()
addInitialBreadcrumbToSentry()

const rootElement = document.getElementById('root')

if (!rootElement) throw Error("Can't find root container to mount react app!")

const root = ReactDOM.createRoot(rootElement)

root.render(
  <React.StrictMode>
    <AppProviders>
      <AppRouter />
    </AppProviders>
    <GlobalStyles />
  </React.StrictMode>
)
