import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { rootReducer } from 'redux/reducers'
import { EventTypes, type EventNotification, type PartialRecord } from 'share/types/core'

export interface LazyEventsSliceState {
  events: PartialRecord<EventTypes, EventNotification>
}

const initialState: LazyEventsSliceState = {
  events: {}
}

const eventsSlice = createSlice({
  name: 'eventsSlice',
  initialState,
  reducers: {
    setEvent(state, { payload: event }: PayloadAction<EventNotification>) {
      state.events = {
        ...state.events,
        [event.payload.code]: event
      }
    },
    removeEvent(state, { payload }: PayloadAction<EventTypes>) {
      delete state.events[payload]
    },
    removeEvents(state, { payload }: PayloadAction<EventTypes[]>) {
      payload.forEach(step => {
        delete state.events[step]
      })
    }
  }
})

const injectedReducer = rootReducer.inject(eventsSlice)

export const { setEvent, removeEvent, removeEvents } = eventsSlice.actions

export const getAllEventsSelector = injectedReducer.selector(state => state.eventsSlice.events)

export const makeSelectEventsByType = (eventTypes: EventTypes[]) =>
  createSelector([getAllEventsSelector], events =>
    eventTypes.map(type => events[type]).filter((event): event is EventNotification => Boolean(event))
  )

export default eventsSlice
