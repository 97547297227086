import TokenService from 'services/token.service'
import { api } from '.'
import type { AuthTokenPair } from 'share/types/api-types/auth-api'

const authApi = api.injectEndpoints({
  endpoints: build => ({
    getCasKeys: build.mutation<AuthTokenPair, void>({
      query: () => ({
        url: '/auth/tokens/pledger/cas-key',
        method: 'post'
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          TokenService.setJWTTokens({
            access: data.access_token,
            refresh: data.refresh_token,
            identity_token: data.identity_token
          })
        } catch (err) {
          console.log(err)
        }
      }
    }),
    generateTestTokens: build.mutation<AuthTokenPair, string | null>({
      query: id => ({
        url: '/test/generate_test_token',
        method: 'post',
        body: {
          data: {
            credit_application_id: id ?? '4df4627d-9bc0-419c-a5c9-b949df12b957',
            owner: 'borrower'
          }
        }
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          TokenService.setJWTTokens({
            access: data.access_token,
            refresh: data.refresh_token,
            identity_token: data.identity_token
          })
        } catch (err) {
          console.log(err)
        }
      }
    })
  })
})

export const { useGetCasKeysMutation, useGenerateTestTokensMutation } = authApi
