/**
 * Custom hook to get an event of a specific type.
 *
 * @param event_type - The type of event to retrieve.
 * @param options - Optional configuration options.
 * @param options.ackMessage - Whether to acknowledge the event message.
 * @param options.removeEventFromStoreWhenAck - Whether to remove the event from the store when acknowledged.
 * @returns The event of the specified type.
 */

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getAllEventsSelector } from 'redux/slices/eventsSlice'
import { type EventTypes } from 'share/types/core'
import { useAcknowledgeEvent } from './useAcknowledgeEvent'

const defaultOptions = {
  ackMessage: false,
  removeEventFromStoreWhenAck: true
}

const useGetEvent = (
  event_type: EventTypes,
  options?: { ackMessage?: boolean; removeEventFromStoreWhenAck?: boolean }
) => {
  const props = {
    ...defaultOptions,
    ...options
  }

  // Selectors
  const { [event_type]: event } = useSelector(getAllEventsSelector)

  // Hooks
  const acknowledgeEvent = useAcknowledgeEvent(event_type, props.removeEventFromStoreWhenAck)

  // Effects
  useEffect(() => {
    if (!props.ackMessage || !event) return
    acknowledgeEvent()
  }, [props.ackMessage, acknowledgeEvent, event])

  return event
}

export { useGetEvent }
