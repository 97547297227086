/**
 * This hook is used to wait for an event to be received and then execute a callback.
 *
 * @param eventType - The type of event to wait for.
 * @param callback - The callback function to execute when the event is received.
 */

import { type EventNotification, EventTypes } from 'share/types/core'
import { useGetEvent } from './useGetEvent'
import { useAcknowledgeEvent } from './useAcknowledgeEvent'
import { useEffect } from 'react'


// This hook is used to wait for an event to be received and then execute a callback
const useAwaitEventRecievedEffect = (eventType: EventTypes, callback: (event: EventNotification) => void) => {
  // Events
  const event = useGetEvent(eventType)

  // Hooks
  const acknowledgeEvent = useAcknowledgeEvent(eventType)

  useEffect(() => {
    if (!event) return
    acknowledgeEvent()
    callback(event)
  }, [acknowledgeEvent, callback, event])
}

export { useAwaitEventRecievedEffect }
