export const pathnames = {
  index: '/',
  lead: '/lead',
  'lead-otp-confirmation': '/lead-otp-confirmation',
  scoring: '/scoring',
  insurance: '/insurance',
  photocontrol: '/photocontrol',
  'waiting-collateral': '/waiting-collateral',
  'pledge-confirmation': '/pledge-confirmation',
  'waiting-estimator': '/waiting-estimator',
  'spouse-confirmation': '/spouse-confirmation',
  'spouse-sms-sended': '/spouse-sms-sended',
  'egov-update-status': '/egov-update-status',
  'digital-docs': '/digital-docs',
  'documents-attachment': '/documents-attachment',
  'documents-generation': '/documents-generation',
  'documents-sign': '/documents-sign',
  'video-identification': '/video-identification',
  'certification-center': '/certification-center',
  'certification-center-otp': '/certification-center-otp',
  'car-final-waiting': '/car-final-waiting',
  'pledge-final-waiting': '/pledge-final-waiting',
  'waiting-page': '/waiting-page',
  success: '/success',
  rejected: '/rejected'
} as const

export type PathnameKeys = keyof typeof pathnames
