import {
  type BaseQueryFn,
  type FetchArgs,
  type FetchBaseQueryError,
  createApi,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react'
import TokenService from 'services/token.service'
import { BASE_API_URL } from 'share/constants'
import { Mutex } from 'async-mutex'
import { type AuthTokenPair } from 'share/types/api-types/auth-api'
import * as Sentry from '@sentry/browser'

const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  prepareHeaders: headers => {
    const token = TokenService.getAccessToken()
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  }
})

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)

  if (result.error) {
    if (typeof args === 'string') {
      Sentry.captureException(result.error, { extra: { meta: result.meta } })
    } else {
      Sentry.captureException(result.error, {
        tags: {
          endpoint: args.url,
          method: args.method
        },
        extra: {
          status: result.error.status,
          body: args.body,
          headers: args.headers
        }
      })
    }
  }

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        const refreshResult = await baseQuery(
          {
            url: '/auth/tokens',
            method: 'POST',
            body: { refresh_token: TokenService.getRefreshToken() }
          },
          api,
          extraOptions
        )
        if (refreshResult.data) {
          const { access_token, refresh_token, identity_token } = refreshResult.data as AuthTokenPair
          TokenService.setJWTTokens({
            access: access_token,
            refresh: refresh_token,
            identity_token
          })
          result = await baseQuery(args, api, extraOptions)
        } else {
          // TODO: Remove tokens from storage and show that session is expired and redirect to superapp
          TokenService.removeJWTTokens()
          alert('Session expired')
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }
  return result
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({})
})
