import type {
  GetBorrowerInfoResponse,
  GetBorrowerSignDocumentsResponse,
  GetConfirmationConditionsResponse,
  GetPersonalServiceResponse,
  GetSelectableConditionsResponse,
  GetStaticDocumentsResponse,
  GetTaskTrackerStatusResponse
} from 'share/types/api-types/loan-api'
import { api } from '.'

const loanApi = api.injectEndpoints({
  endpoints: build => ({
    getStaticDocuments: build.query<GetStaticDocumentsResponse, void>({
      query: () => ({
        url: '/static/documents',
        method: 'GET'
      })
    }),
    getTaskTrackerStatus: build.query<GetTaskTrackerStatusResponse, null | void>({
      query: () => ({
        url: '/status',
        method: 'GET'
      })
    }),
    getBorrowerInfo: build.query<GetBorrowerInfoResponse, void>({
      query: () => ({
        url: '/credit-application/borrower'
      })
    }),
    getPersonalService: build.query<GetPersonalServiceResponse, void>({
      query: () => ({
        url: '/personal_service/',
        method: 'get'
      })
    }),
    attachBankStatements: build.mutation<void, FormData>({
      query: formData => ({
        url: '/credit-application/attach-account-statements',
        method: 'post',
        body: formData
      })
    }),
    getSelectableConditions: build.query<GetSelectableConditionsResponse, void>({
      query: () => ({
        url: '/credit-application/selectable-conditions'
      })
    }),
    getConfirmationConditions: build.query<GetConfirmationConditionsResponse, void>({
      query: () => ({
        url: '/credit-application/confirmation-conditions'
      })
    }),
    getBorrowerSignDocuments: build.query<GetBorrowerSignDocumentsResponse, void>({
      query: () => ({
        url: '/credit-application/borrower-signs-documents'
      })
    })
  })
})

export const {
  useGetStaticDocumentsQuery,
  useGetTaskTrackerStatusQuery,
  useGetBorrowerInfoQuery,
  useGetSelectableConditionsQuery,
  useGetConfirmationConditionsQuery,
  useGetBorrowerSignDocumentsQuery,
  useGetPersonalServiceQuery,
  useAttachBankStatementsMutation
} = loanApi
