import 'twin.macro'
import { css } from '@emotion/react'
import tw from 'twin.macro'
import type { HTMLAttributes } from 'react'

const ringLoaderStyles = css`
  --stroke-width: var(--ring-stroke-width, 8.8px);

  ${tw`animate-spin`}

  position: relative;
  width: calc(100% - 28%);
  height: calc(100% - 28%);
  border-radius: 50%;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background-image: conic-gradient(
      from 90deg at 50% 50%,
      transparent 0deg,
      transparent 90.63deg,
      rgba(93, 184, 113, 0.81) 310deg,
      var(--green-500) 360deg
    );
    mask: radial-gradient(farthest-side, transparent calc(100% - var(--stroke-width)), #fff 0);
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    z-index: 1;
    right: 0;
    transform: translateY(-50%);
    width: var(--stroke-width);
    max-width: var(--stroke-width);
    height: var(--stroke-width);
    max-height: var(--stroke-width);
    border-radius: 50%;
    background: var(--green-500);
  }
`

const RingLoader = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div tw='w-40 h-40 grid place-items-center rounded-full bg-day-50' {...props}>
      <div css={[ringLoaderStyles]} />
    </div>
  )
}

export default RingLoader
