import { AuthProvider } from 'contexts/auth-context'
import { EventsWebSocketProvider } from 'contexts/websocket-context'
import { type PropsWithChildren } from 'react'
import { Provider } from 'react-redux'
import { store } from 'redux/store'

const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <EventsWebSocketProvider>{children}</EventsWebSocketProvider>
      </AuthProvider>
    </Provider>
  )
}

export default AppProviders
