interface WebSocketManagerOptions {
  reconnectOnPingPongFailed: boolean
  maxAttempts: number
  maxMissedPongs: number
  reconnectDelay: number
  pingInterval: number
}

const defaultOptions: WebSocketManagerOptions = {
  reconnectOnPingPongFailed: true,
  maxAttempts: 10,
  maxMissedPongs: 5,
  reconnectDelay: 5000,
  pingInterval: 5000
}

export class WebSocketManager<Message> {
  private url: string
  private websocket: WebSocket | null
  private attempts: number
  private pings: number
  readonly abortController: AbortController
  readonly reconnectOnPingPongFailed: boolean
  readonly reconnectDelay: number
  readonly maxAttempts: number
  readonly maxMissedPongs: number
  readonly pingInterval: number
  readonly pingMessage = JSON.stringify({ type: 'command', key: 'ping' })
  // Timer ids
  private healthCheckId: NodeJS.Timeout | undefined
  private reconnectAttemptsId: NodeJS.Timeout | undefined
  // Callbacks
  public checkPingPongMessageFn: ((message: Message) => boolean) | undefined
  public onOpenConnection: (() => void) | undefined
  public onMaxAttemptsReached: (() => void) | undefined
  public onMessage: ((websocket: WebSocket, message: Message) => void) | undefined
  public onClose: (() => void) | undefined
  public onError: ((error?: Event) => void) | undefined

  constructor(url: string, options?: Partial<WebSocketManagerOptions>) {
    const abortController = new AbortController()

    this.url = url
    const mergedOptions = { ...defaultOptions, ...options }

    this.websocket = null
    this.abortController = abortController
    this.reconnectOnPingPongFailed = mergedOptions.reconnectOnPingPongFailed
    this.maxAttempts = mergedOptions.maxAttempts
    this.maxMissedPongs = mergedOptions.maxMissedPongs
    this.pingInterval = mergedOptions.pingInterval
    this.reconnectDelay = mergedOptions.reconnectDelay
    this.attempts = 0
    this.pings = 0

    this.initAbortSignal()
  }

  public async connect(): Promise<WebSocket> {
    return await this.createWebSocket()
  }

  public abort() {
    return this.abortController.abort()
  }

  private initAbortSignal() {
    this.abortController.signal.addEventListener('abort', () => {
      this.cleanup()
      this.websocket?.close()
    })
  }

  private async createWebSocket(): Promise<WebSocket> {
    return new Promise((resolve, reject) => {
      // Check if websocket is not null and already opened, do nothing
      if (this.websocket && this.websocket.readyState === this.websocket.OPEN) return

      const websocket = new WebSocket(this.url)
      this.websocket = websocket

      websocket.addEventListener('open', () => {
        this.startHealthCheck()
        clearInterval(this.reconnectAttemptsId)
        resolve(websocket)
        this.onOpenConnection?.()
      })

      // Here we can ensure that socket opened and we recieving messages
      websocket.addEventListener('message', (e: MessageEvent) => {
        if (this.attempts > 0) {
          // Reset attempts if message recieved
          this.attempts = 0
        }
        const data = JSON.parse(e.data as string) as Message
        this.onMessage?.(websocket, data)
        this.checkMessage(data)
      })

      websocket.addEventListener('close', () => {
        reject('Socket closed')
        this.onClose?.()
      })

      websocket.addEventListener('error', (error: Event) => {
        this.onError?.(error)
      })
    })
  }

  private sendPing() {
    if (!this.websocket || this.websocket.readyState !== this.websocket.OPEN) return this.pings++
    this.websocket.send(this.pingMessage)
    this.pings++
  }

  private checkMessage(message: Message) {
    try {
      if (!this.checkPingPongMessageFn) throw new Error('checkPingPongMessageFn must be defined!')
      // Reset pings if message recieved and message.type is command_processing_result and message.value is pongs
      if (this.checkPingPongMessageFn(message)) {
        this.pings = 0
      }
    } catch {
      // if checkPingPongMessageFn is not defined, catch clause will be executed then onError if provided will be called
      this.onError?.()
    }
  }

  private startPingPong() {
    try {
      this.sendPing()
      if (this.pings > this.maxMissedPongs) {
        if (this.reconnectOnPingPongFailed) {
          clearInterval(this.healthCheckId)
          throw new Error('Too may missed heartbeats')
        } else {
          this.cleanup()
        }
      }
    } catch {
      this.pings = 0
      clearInterval(this.healthCheckId)
      this.websocket?.close()
      this.startReconnect()
    }
  }

  private startHealthCheck() {
    this.startPingPong()
    this.healthCheckId = setInterval(() => {
      this.startPingPong()
    }, this.pingInterval)
  }

  private startReconnect() {
    console.log('Socket closed because ping pong is not responing! Trying to reconnect...')
    this.reconnectAttemptsId = setInterval(() => {
      this.reconnect().catch(console.error)
    }, this.reconnectDelay)
  }

  private async reconnect() {
    // Additional guard to prevent reconnecting if websocket is already opened
    if (this.websocket && this.websocket.readyState === this.websocket.OPEN) {
      return this.cleanup()
    }
    if (this.attempts >= this.maxAttempts && this.websocket && this.websocket.readyState !== this.websocket.OPEN) {
      console.log(`Max attempts reached! Can't connect to socket after ${this.attempts} attempts! Run cleanup!`)
      this.cleanup()
      this.onMaxAttemptsReached?.()
      return
    }
    this.attempts++
    console.log('Reconnecting to websocket...')
    await this.connect()
  }

  private cleanup() {
    console.log('Running cleanup...')
    clearInterval(this.healthCheckId)
    clearInterval(this.reconnectAttemptsId)
  }
}
