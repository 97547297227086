/**
 * This hook is used to check if any of the events are fired and if they are, then it will acknowledge them and call the callback.
 *
 * @param events - An array of event types to check for.
 * @param cleanupEvents - An optional array of event types to acknowledge if present.
 * @param callback - An optional callback function to be called after acknowledging the events.
 */

import { type EventNotification, EventTypes } from 'share/types/core'
import { useAppSelector } from './useRedux'
import { getAllEventsSelector } from 'redux/slices/eventsSlice'
import { useEffect, useMemo } from 'react'
import { pathnames } from 'router/pathnames'
import { useAcknowledgeEvent } from './useAcknowledgeEvent'
import { router } from 'router'

const useCheckRejectEventsEffect = (
  { events, cleanupEvents = [] }: { events: EventTypes[]; cleanupEvents?: EventTypes[] },
  callback?: () => void
) => {
  // Selectors
  const wsEvents = useAppSelector(getAllEventsSelector)

  // Memoized values
  const eventsToAcknowledge = useMemo(() => [...events, ...cleanupEvents], [cleanupEvents, events])
  const foundedEvents = useMemo(
    () => events.map(event => wsEvents[event]).filter((event): event is EventNotification => !!event),
    [events, wsEvents]
  )

  // Hooks
  const acknowledgeEvent = useAcknowledgeEvent(eventsToAcknowledge)

  // Effect
  useEffect(() => {
    if (foundedEvents.length === 0) return
    acknowledgeEvent()
    if (!callback) {
      void router.navigate(pathnames.rejected)
      return
    }

    callback()
  }, [acknowledgeEvent, callback, foundedEvents.length])
}

export { useCheckRejectEventsEffect }
