import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { type ReactNode, createContext, useEffect, useState } from 'react'
import { selectIsAuthTokensPresent, setProgress, setWebSocketState } from 'redux/slices/appStateSlice'
import { setEvent } from 'redux/slices/eventsSlice'
import TokenService from 'services/token.service'
import { BASE_WS_URL } from 'share/constants'
import { NotificationTypes, type SocketNotifications } from 'share/types/core'
import { getProgressByWsEvent } from 'utils/app-steps'
import { WebSocketManager } from 'utils/sockets/web-socket-manager'
import * as Sentry from '@sentry/browser'

export type EventsWebSocketContextType = WebSocket | null

const EventsWebSocketContext = createContext<EventsWebSocketContextType>(null)

export const EventsWebSocketProvider = ({ children }: { children: ReactNode }) => {
  // Tools
  const dispatch = useAppDispatch()

  // Selectors
  const isAuthTokensPresent = useAppSelector(selectIsAuthTokensPresent)

  // State
  const [ws, setWs] = useState<EventsWebSocketContextType>(null)

  useEffect(() => {
    if (!isAuthTokensPresent) return
    const identityToken = TokenService.getIdentityToken()
    if (!identityToken) return

    const url = `${BASE_WS_URL}?identity_token=${identityToken}`

    const eventsWs = new WebSocketManager<SocketNotifications>(url)
    eventsWs.checkPingPongMessageFn = message =>
      message.type === NotificationTypes.commandProcessingResult && message.value === 'pong'

    eventsWs.onMessage = (_, message) => {
      if (message.type === NotificationTypes.serverNotification) {
        dispatch(setEvent(message))
        dispatch(setProgress(getProgressByWsEvent(message.payload.code)))
      }
    }

    const connectToEventsWs = async () => {
      const ws = await eventsWs.connect()
      setWs(ws)
    }

    connectToEventsWs()
      .then(() => {
        dispatch(setWebSocketState('success'))
      })
      .catch(err => {
        if (eventsWs.abortController.signal.aborted) return
        Sentry.captureMessage('Failed to connect to eventsWs', {
          level: 'error',
          extra: {
            error: err
          }
        })
        dispatch(setWebSocketState('error'))
      })

    return () => {
      eventsWs.abort()
    }
  }, [dispatch, isAuthTokensPresent])

  return <EventsWebSocketContext.Provider value={ws}>{children}</EventsWebSocketContext.Provider>
}

export default EventsWebSocketContext
