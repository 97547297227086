import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { kkResources, ruResources } from './locales/resources'

export const defaultNS = 'main'
export const resources = {
  kk: kkResources,
  ru: ruResources
} as const

const languageDetector = new LanguageDetector(null, {
  caches: ['sessionStorage'],
  lookupSessionStorage: 'fil-i18n-lang',
  lookupQuerystring: 'lang',
  order: ['querystring', 'sessionStorage']
})

void i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['kk', 'ru'],
    resources,
    fallbackLng: 'ru',
    debug: import.meta.env.DEV,
    ns: ['common', 'form', 'main'],
    defaultNS: 'main',
    interpolation: {
      escapeValue: false
    }
  })

i18n.languages = ['kk', 'ru']

export default i18n
