import { ACCESS_TOKEN_KEY, IDENTITY_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'share/constants'

const getAccessToken = () => sessionStorage.getItem(ACCESS_TOKEN_KEY)
const getRefreshToken = () => sessionStorage.getItem(REFRESH_TOKEN_KEY)
const getIdentityToken = () => sessionStorage.getItem(IDENTITY_TOKEN_KEY)
const getJWTTokens = () => ({
  access_token: sessionStorage.getItem(ACCESS_TOKEN_KEY),
  refresh_token: sessionStorage.getItem(REFRESH_TOKEN_KEY),
  identity_token: sessionStorage.getItem(IDENTITY_TOKEN_KEY)
})

const setAccessToken = (token: string) => sessionStorage.setItem(ACCESS_TOKEN_KEY, token)
const setRefreshToken = (token: string) => sessionStorage.setItem(REFRESH_TOKEN_KEY, token)
const setIdentityToken = (token: string) => sessionStorage.setItem(IDENTITY_TOKEN_KEY, token)
const setJWTTokens = ({
  access,
  refresh,
  identity_token
}: {
  access: string
  refresh: string
  identity_token: string
}) => {
  sessionStorage.setItem(ACCESS_TOKEN_KEY, access)
  sessionStorage.setItem(REFRESH_TOKEN_KEY, refresh)
  sessionStorage.setItem(IDENTITY_TOKEN_KEY, identity_token)
}

const removeAccessToken = () => sessionStorage.removeItem(ACCESS_TOKEN_KEY)
const removeRefreshToken = () => sessionStorage.removeItem(REFRESH_TOKEN_KEY)
const removeIdentityToken = () => sessionStorage.removeItem(IDENTITY_TOKEN_KEY)
const removeJWTTokens = () => {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY)
  sessionStorage.removeItem(REFRESH_TOKEN_KEY)
  sessionStorage.removeItem(IDENTITY_TOKEN_KEY)
}

const isAuthJWTsSettled = () => {
  return !!getAccessToken() && !!getRefreshToken() && !!getIdentityToken()
}

const TokenService = {
  getJWTTokens,
  getAccessToken,
  getRefreshToken,
  getIdentityToken,
  setJWTTokens,
  setAccessToken,
  setRefreshToken,
  setIdentityToken,
  removeJWTTokens,
  removeAccessToken,
  removeRefreshToken,
  removeIdentityToken,
  isAuthJWTsSettled
}

export default TokenService
