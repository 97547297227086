import { Global } from '@emotion/react'
import { Fragment } from 'react'
import tw, { css, GlobalStyles as BaseStyles } from 'twin.macro'

const customStyles = css({
  body: {
    ...tw`antialiased bg-main text-main overflow-x-hidden`,
    fontFamily: 'SFProDisplay, sans-serif'
  },
  '#root': tw`flex flex-col min-h-dvh`,
  ':root': {
    '--day-50': '#FFFFFF',
    '--day-100': '#F7F7F7',
    '--day-200': '#EAECED',
    '--day-300': '#D1D1D6',
    '--day-400': '#AEAEB2',
    '--day-500': '#8E8E93',
    '--day-700': '#636366',
    '--day-800': '#3A3A3C',
    '--day-900': '#0F0F0F',
    '--day-1000': '#007AFF',

    '--night-300': '#3A3A3C',

    '--green-300': '#B8E4C7',
    '--green-500': '#4EBC73',
    '--green-700': '#72C183',
    '--red-500': '#FF3347',
    '--red-700': '#DB2547'
  },
  '.light': {
    // Colors that changes between light and dark mode
    '--primary': 'var(--green-500)',
    '--primary-darker': 'var(--green-700)',
    '--primary-disabled': 'var(--green-300)',
    '--secondary': 'var(--day-200)',

    // Background colors
    '--bg-main': 'var(--day-100)',
    '--bg-card': 'var(--day-50)',
    '--bg-modal': '#F2F2F2',

    // Text colors
    '--text-main': 'var(--day-900)',
    '--text-primary': 'var(--green-500)',
    '--text-secondary': 'var(--day-700)',
    '--text-tertiary': 'var(--day-500)',
    '--text-modal': 'var(--day-1000)'
  },
  '.dark': {
    // Colors that changes between light and dark mode
    '--primary': 'var(--green-500)',
    '--primary-darker': 'var(--green-700)',
    '--primary-disabled': 'var(--green-300)',
    '--secondary': 'var(--day-200)',

    // Background colors
    '--bg-main': 'var(--day-100)',
    '--bg-card': 'var(--day-50)',
    '--bg-modal': '#F2F2F2',

    // Text colors
    '--text-main': 'var(--day-900)',
    '--text-primary': 'var(--green-500)',
    '--text-secondary': 'var(--day-700)',
    '--text-tertiary': 'var(--day-500)',
    '--text-modal': 'var(--day-1000)'
  }
})

const GlobalStyles = () => (
  <Fragment>
    <BaseStyles />
    <Global styles={customStyles} />
  </Fragment>
)

export default GlobalStyles
