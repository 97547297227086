/**
 * Custom hook for acknowledging events.
 * 
 * @param events - An array of event types or a single event type.
 * @param removeEventFromStoreWhenAck - A boolean indicating whether to remove the event from the store when acknowledged. Default is true.
 * @returns A function to acknowledge the events.
 */

import { type EventNotification, EventTypes } from 'share/types/core'
import { acknowledgeEvent } from 'utils/event-helperts'
import { useAppDispatch, useAppSelector } from './useRedux'
import { getAllEventsSelector, removeEvent } from 'redux/slices/eventsSlice'
import { useCallback, useMemo } from 'react'
import { useEventsSocket } from './useEventsSocket'

const useAcknowledgeEvent = (events: EventTypes[] | EventTypes, removeEventFromStoreWhenAck = true) => {
  // Tools
  const dispatch = useAppDispatch()

  // Selectors
  const wsEvents = useAppSelector(getAllEventsSelector)

  // Memoized values
  const foundedEvents = useMemo(() => {
    if (Array.isArray(events)) {
      return events.map(event => wsEvents[event]).filter((event): event is EventNotification => !!event)
    } else {
      const event = wsEvents[events]
      return event ? [event] : []
    }
  }, [events, wsEvents])

  // Hooks
  const ws = useEventsSocket()

  const acknowledgeEvents = useCallback(() => {
    if (!ws) return
    foundedEvents.forEach(event => {
      if (removeEventFromStoreWhenAck) {
        dispatch(removeEvent(event.payload.code))
      }
      acknowledgeEvent(ws, event.key)
    })
  }, [dispatch, foundedEvents, removeEventFromStoreWhenAck, ws])

  return acknowledgeEvents
}

export { useAcknowledgeEvent }
