import type {
  ConfirmInitialLoanConditionsPayload,
  SendPhotocontrolPhotosPayload,
  SendSpousePhoneNumberPayload,
  VerifyOTPPayload,
  DocumentUploadPayload
} from 'share/types/api-types/main-events-api'
import { api } from '.'
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query'

const mainEventsApi = api.injectEndpoints({
  endpoints: build => ({
    initiateLoan: build.mutation<void, void>({
      query: () => ({
        url: 'main/events/borrower-initiated-loan',
        method: 'post'
      })
    }),
    refuseLoan: build.mutation<void, void>({
      query: () => ({
        url: 'main/events/borrower-refused-loan',
        method: 'post'
      })
    }),
    confirmInitialLoanConditions: build.mutation<void, ConfirmInitialLoanConditionsPayload>({
      query: body => ({
        url: 'main/events/borrower-conditions-selected',
        method: 'post',
        body
      })
    }),
    verifyOTP: build.mutation<void, VerifyOTPPayload>({
      query: body => ({
        url: 'main/events/borrower-entered-phone-verification-code',
        method: 'post',
        body
      })
    }),
    resendOTPCode: build.mutation<void, void>({
      query: () => ({
        url: 'main/events/borrower-requested-new-phone-verification-code',
        method: 'post'
      })
    }),
    borrowerChoseFreedomInsurance: build.mutation<void, void>({
      query: () => ({
        url: 'main/events/borrower-chose-freedom',
        method: 'post'
      })
    }),
    borrowerChoseAnotherCompany: build.mutation<void, void>({
      query: () => ({
        url: 'main/events/borrower-chose-another-company',
        method: 'post'
      })
    }),
    sendPhotocontrolPhotos: build.mutation<void, SendPhotocontrolPhotosPayload>({
      query: body => ({
        url: 'main/events/borrower-provided-car-photo',
        method: 'post',
        body,
        timeout: 60000 // 1 min timeout
      })
    }),
    confirmLoanConditions: build.mutation<void, void>({
      query: () => ({
        url: 'main/events/borrower-agreed-conditions-loan',
        method: 'post'
      })
    }),
    confirmBorrowerNotMarried: build.mutation<void, void>({
      query: () => ({
        url: 'main/events/borrower-is-not-married',
        method: 'post'
      })
    }),
    sendSpousePhoneNumber: build.mutation<void, SendSpousePhoneNumberPayload>({
      query: body => ({
        url: 'main/events/borrower-provided-spouse-phone',
        method: 'post',
        body
      })
    }),
    verifyDigitalDocsOTP: build.mutation<void, VerifyOTPPayload>({
      query: body => ({
        url: 'main/events/borrower-entered-providing-digital-documents-code',
        method: 'post',
        body
      })
    }),
    resendDigitalDocsOTP: build.mutation<void, void>({
      query: () => ({
        url: 'main/events/borrower-requested-new-providing-digital-documents-code',
        method: 'post'
      })
    }),
    startDocumentsSigning: build.mutation<void, void>({
      query: () => ({
        url: 'main/events/borrower-initiated-documents-signing',
        method: 'post'
      })
    }),
    borrowerInitiatedVideoIdentification: build.mutation<void, void>({
      query: () => ({
        url: 'main/events/borrower-initiated-video-identification',
        method: 'post'
      })
    }),
    documentUpload: build.mutation<null, DocumentUploadPayload>({
      queryFn: async ({ document_type, file }, _api, _extraOptions, fetchWithBQ) => {
        try {
          const formData = new FormData()
          formData.append('file', file)
          await fetchWithBQ({
            url: `main/events/document-upload/${document_type}`,
            method: 'post',
            body: formData
          })
          return {
            data: null
          }
        } catch (error) {
          return {
            error: error as FetchBaseQueryError
          }
        }
      }
    }),
    borrowerUpdatedEGovStatus: build.mutation<void, void>({
      query: () => ({
        url: 'main/events/borrower-reported-that-updated-marital-status',
        method: 'post'
      })
    }),
    dataTransfferedToForensics: build.mutation<void, string>({
      query: id => ({
        url: 'main/events/data-transferred-to-forensics',
        method: 'post',
        body: {
          subject_identifier: id
        }
      })
    }),
    verifyCertificationOtpCode: build.mutation<void, VerifyOTPPayload>({
      query: body => ({
        url: 'main/events/borrower-signature-confirmation-entered-phone-verification-code',
        method: 'post',
        body
      })
    }),
    resendCertificationOtpCode: build.mutation<void, void>({
      query: () => ({
        url: 'main/events/borrower-signature-confirmation-requested-new-phone-verification-code',
        method: 'post'
      })
    })
  })
})

export const {
  useInitiateLoanMutation,
  useRefuseLoanMutation,
  useConfirmInitialLoanConditionsMutation,
  useVerifyOTPMutation,
  useResendOTPCodeMutation,
  useBorrowerChoseFreedomInsuranceMutation,
  useBorrowerChoseAnotherCompanyMutation,
  useSendPhotocontrolPhotosMutation,
  useConfirmLoanConditionsMutation,
  useConfirmBorrowerNotMarriedMutation,
  useSendSpousePhoneNumberMutation,
  useVerifyDigitalDocsOTPMutation,
  useResendDigitalDocsOTPMutation,
  useStartDocumentsSigningMutation,
  useBorrowerInitiatedVideoIdentificationMutation,
  useDocumentUploadMutation,
  useBorrowerUpdatedEGovStatusMutation,
  useDataTransfferedToForensicsMutation,
  useVerifyCertificationOtpCodeMutation,
  useResendCertificationOtpCodeMutation
} = mainEventsApi
