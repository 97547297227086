import { combineSlices } from '@reduxjs/toolkit'
import { api } from 'services/api'
import eventsSlice, { type LazyEventsSliceState } from './slices/eventsSlice'
import appStateSlice, { type LazyAppStateSliceState } from './slices/appStateSlice'
import leadSlice, { type LazyLeadSliceState } from './slices/leadSlice'

export interface LazyLoadedSlices {
  [appStateSlice.reducerPath]: LazyAppStateSliceState
  [eventsSlice.reducerPath]: LazyEventsSliceState
  [leadSlice.reducerPath]: LazyLeadSliceState
}

export const rootReducer = combineSlices(api).withLazyLoadedSlices<LazyLoadedSlices>()
