import kkCommon from 'i18n/locales/kk/common.json'
import kkMain from 'i18n/locales/kk/main.json'
import kkForm from 'i18n/locales/kk/form.json'
import ruCommon from 'i18n/locales/ru/common.json'
import ruMain from 'i18n/locales/ru/main.json'
import ruForm from 'i18n/locales/ru/form.json'

const ruResources = {
  main: ruMain,
  common: ruCommon,
  form: ruForm
}

const kkResources = {
  main: kkMain,
  common: kkCommon,
  form: kkForm
}

export { ruResources, kkResources }
